body {
	padding: 0px;
	font-family: $base-font-family;
	font-size: $base-font-size;
	line-height: $base-line-height;
	color: $color-text;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.load-overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	background: #fff;
	left: 0px;
	top: 0px;
	z-index: 1000;
}