.bottom-cta {
	width: 100%;
	float: left;
	background: #0aabf6;
	color: #fff;
	padding: 50px;
	text-align: center;

	span.subtitle, h3 {
		width: 100%;
		float: left;
		font-size: 32px;
		margin: 0px 0px 5px 0px;
	}

	span.subtitle {
		font-size: 24px;
		margin: 0px 0px 20px 0px;
	}

	h3 {
		font-weight: bold;

		span {
			font-weight: $bold;
			text-decoration: underline;
		}
	}

	.btn {
		position: relative;
		background: #7bb638;
		display: inline-block;

		&:hover {
			background: darken(#7bb638, 5);
		}
	}
}