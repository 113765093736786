/*--------------------------------------------------------------
# Style
--------------------------------------------------------------*/
.admin-bar {
	.top-bar {
		top: 32px;
	}
}
.top-bar {
	width: 100%;
	position: fixed;
	padding: 15px 0px;
	top: 0px;
	z-index: 9999;
	transition: all 0.4s ease;

	ul {
		float: right;
		list-style: none;
		padding: 0px;
		margin: 3px 0px 0px 0px;

		li {
			float: left;

			a {
				font-weight: $medium;
				padding: 12px 10px;
				color: $color-text;
				
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.logo {
		float: left;
		margin-top: 2px;
		font-size: 21px;
		color: $color-text;

		img {
			max-height: 35px;
			max-width: 80%;
			display: inline-block;
		}

		span {
			font-weight: $bold;
			color: #c3ab27;
		}

		&:hover {
			text-decoration: none;
		}
	}

	.usps {
		width: 700px;
		float: right;

		.usp {
			float: left;
			padding: 10px;
			font-weight: bold;

			.fa {
				color: #7bb638;
			}
		}
	}

	.btn {
		opacity: 1;
	}

	&.scroll {
		box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
		background: #fff;

		.btn {
			opacity: 1;
		}
	}
}

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/
@include breakpoint(lg) {
	.top-bar .usps {
		width: 100%;
		text-align: center;
	}

	.top-bar .logo{
		width: 100%;
		text-align: center;
	}
}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}