/*--------------------------------------------------------------
# Style
--------------------------------------------------------------*/
.hero {
	width: 100%;
	padding-bottom: 50px;
	background: #eff5f7;

	.container {
		padding: 0px 150px;
	}

	.overlay {
		width: 100%;
		float: left;
		color: #0aabf6;
		text-decoration: underline;
		padding-top: 25px;
		padding-bottom: 10px;
		background: rgb(255,255,255);
		background: linear-gradient(0deg, rgba(255,255,255,1) 29%, rgba(255,255,255,0) 100%);
		font-size: 14px;
		margin-top: -35px;
		position: relative;
	}
	
	h1 {
		text-align: center;
		width: 100%;
		display: block;
		font-weight: bold;
		color: #0aabf6;
		margin: 100px 0px 0px 0px;

		span {
			font-weight: 300;
			text-decoration: underline;
			font-size: 15px;
		}
	}

	.subtitle {
		font-size: 26px;
		display: block;
		text-align: center;
		margin: 5px 0px 75px 0px;
	}

	.stars {
		.fa {
			display: inline-block;
			color: #6f6f6f;
			font-size: 18px;

			&.active {
				color: #ecc010;
			}
		}

		p {
			display: block;
			margin: 0px;
			font-size:14px;
		}

		span {
			font-weight: $bold;
		}
	}

	.usp {
		width: 100%;
		float: left;
		padding-left: 40px;
		position: relative;
		margin-bottom: 25px;

		.title {
			font-weight: $bold;
		}

		.fa {
			color: #fff;
			text-align: center;
			line-height: 30px;
			font-size: 21px;
			position: absolute;
			left: 0px;
			top: 50%;
			margin-top: -20px;
			background: $color-primary;
			width: 30px;
			height: 30px;
			border-radius: 40px;
		}
	}

	.submit-form {
		width: 400px;
		box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.08);
		background: #fff;
		margin: -50px 0px -100px 0px;
		padding: 30px;

		.wpcf7-not-valid-tip {
			display: none !important;
		}

		.wpcf7-response-output {
			width: 100%;
			margin: 10px 0px 0px 0px;
			padding: 5px;
			font-size: 14px;
			line-height: 21px;

			&.wpcf7-validation-errors {
				display: none !important;
			}
		}

		h4 {
			font-size: 21px;
			line-height: 28px;
			font-weight: $medium;
			text-align: left;
			margin: 0px;

			span {
				font-weight: bold;
				text-decoration: underline;
			}
		}

		p {
			font-size: 14px;
			line-height: 21px;
		}

		.field {
			width: 100%;
			float: left;
			margin: 0px 0px 10px 0px;

			.label {
				font-weight: $medium;
				font-size: 14px;

				sup {
					color: #f00;
				}
			}

			input {
				border: 1px solid #d3d2d2;
				width: 100%;
				box-sizing: border-box;
				padding: 8px 10px;
				border-radius: 2px;
				box-shadow: inset 1px 2px 2px 0 rgba(0,0,0,.08);
				float: left;

				&.wpcf7-not-valid {
					border: 1px solid #f00;
				}
			}
		}

		input[type="submit"] {
			background: $color-primary;
			color: #fff;
			padding: 8px 20px;
			border: 0px;
			width: 100%;
			font-size: 18px;
			font-weight: $medium;
			border-radius: 4px;

			&:hover {
				color: #fff;
				text-decoration: none;
				background: darken($color-primary, 5);
			}
		}

		.ajax-loader {
			display: none !important;
		}

		.req {
			font-size: 14px;
			display: block;
		}
	}

	.tabs {
		width: 100%;
		float: left;
		margin-top: 100px;

		a {
			padding: 10px 20px;
			float: left;
			background: darken(#eff5f7, 5);
			margin-right: 5px;
			border-radius: 5px 5px 0px 0px;
			text-decoration: none;

			.fa {
				margin-left: 20px;
			}

			&:hover {
				background: darken(#eff5f7, 10);
			}
		}
	}

	.compare_box {
		width: 100%;
		background: #fff;
		padding: 30px;
		float: left;
		box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
		//margin-bottom: -90px;
		z-index: 1000;
		position: relative;

		h1 {
			font-size: 26px;
			margin-top: 0px;
		}

		.subtitle {
			font-size: 18px;
			margin: 5px 0px 10px 0px;
		}

		.item {
			width: 100%;
			border-top: 1px solid #eee;
			padding-bottom: 10px;
			margin-bottom: 10px;
			float: left;
			padding: 30px 0px;

			.num {
				width: 40px;
				height: 40px;
				border-radius: 40px;
				background: #7bb638;
				color: #fff;
				position: absolute;
				text-align: center;
				line-height: 38px;
				font-weight: bold;
				font-size: 26px;
			}

			.usps-list {
				font-size: 14px;

				.fa {
					color: #000;
					top: -1px;
					position: relative;
					font-size: 11px;
				}

				.usp-item {
					margin-bottom: 10px;
				}
			}

			.price {
				font-size: 21px;
				font-weight: $bold;
				margin: 0px 0px 5px 0px;
			}

			.btn {
				font-size: 14px;
				float: left;
				padding: 8px 15px;
				margin-bottom: 10px;

				.fa {
					margin-top: 3px;
					margin-right: 5px;
				}
			}

			h3 {
				margin: 0px 0px 5px 0px;
				font-size: 18px;
				font-weight: $bold;
			}
			
			img {
				text-align: center;
				max-width: 90%;
				height: auto;
			}

			p {
				font-size: 13px;
			}

			.line {
				width: 100%;
				float: left;
				border-bottom: 1px solid #eee;
				padding: 5px;

				&:hover {
					opacity: 0.8;
				}

				.partner-price {
					float: right;
					font-weight: bold;
					color: #7bb638;
				}

				img {
					max-width: 80px;
					max-height: 30px;
					float: left;
				}
			}
		}
	}

	.compare-page {
		.container {
			padding: 0px 75px;
		}
	}
}

.logos {
	width: 100%;
	padding: 50px 0px;
	border-bottom: 1px solid #eee;
	margin-bottom: 40px;

	.logo {
		height: 50px;
		width: 100px;
		float: left;
		position: relative;
		padding: 0 20px;

		img {
			max-height: 90%;
			max-width: 90%;
			width: auto;
			height: auto;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
		}
	}
}

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {
	.hero .container {
		padding: 0px 10px;

		.logo {
			text-align: center;
			margin-bottom: 30px;
			max-height: 200px;
		}

		.col-md-4 {
			text-align: center;
		}

		.btn {
			margin-bottom: 20px;
			width: 100%;
			margin-top: 20px;
			text-align: center;
			padding: 10px !important;
			font-weight: bold;
		}

		.item .line {
			box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);
			margin-bottom: 3px;
			margin: 5px 0px;
			padding: 4px 10px;
		}
	}

	.hero .tabs {
		a {
			width: 100%;
			margin: 0px 0px 10px 0px;
			border-radius: 5px;

			.fa {
				float: right;
				margin-top: 5px;
			}
		}
	}
}

@include breakpoint(sm) {

}