/*--------------------------------------------------------------
# Style
--------------------------------------------------------------*/
.page-temp {
	padding: 100px 0px 100px 0px;
	float: left;
	width: 100%;

	a {
		color: $color-text;
		text-decoration: underline;
	}

	h1 {
		font-size: 36px;
		font-weight: $medium;
		margin: 0px 0px 10px 0px;
	}

	.content {
		padding: 0px;
	}

	.alert-warning {
		h4 {
			font-weight: $medium;
			font-size: 24px;
		}
	}
} 

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}