/*--------------------------------------------------------------
# Style
--------------------------------------------------------------*/
.btn {
	background: #0aabf6;
	color: #fff !important;
	padding: 8px 20px;
	border: 0px;
	border-radius: 4px;
	font-weight: $medium;
	text-decoration: none !important;

	&:hover {
		color: #fff;
		text-decoration: none;
		background: darken(#0aabf6, 5);
	}

	.fa {
		margin-left: 10px;
	}

	&.right {
		float: right;
	}
}

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {}

@include breakpoint(sm) {}
