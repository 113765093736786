.content {
	width: 100%;
	float: left;
	padding: 50px;

	h1, h2, h3, h4, h5, h6 {
		width: 100%;
		margin: 0px 0px 5px 0px;
		font-weight: bold;
		font-size: 21px;
		line-height: 28px;

		span {
			font-weight: $bold;
		}
	}

	h1, h2 {
		font-size: 24px;
	}

	p {
		margin: 0px 0px 20px 0px;
	}

	a {
		color: $color-text;
		text-decoration: underline;
	}
}

@include breakpoint(sm) {
	.content {
		padding: 20px 10px;
	}
}