.faq, .content .faq {
	width: 100%;
	float: left;

	h4 {
		margin: 0px 0px 10px 0px;
	}

	.faq-item {
		width: 100%;

		&.open {
			h3 {
				.fa {
					transform: rotate(90deg);
				}
			}

			.inner {
				display: block;
			}
		}

		h3 {
			background: $color-light;
			padding: 10px 30px 10px 10px;
			position: relative;
			font-size: 16px;
			border-radius: 4px;
			cursor: pointer;

			&:hover {
				background: darken($color-light, 5);
			}
	
			.fa {
				position: absolute;
				right: 20px;
				top: 50%;
				margin-top: -13px;
				font-size: 26px;
				transition: all 0.5s ease;
			}
		}

		.inner {
			padding: 20px;
			width: 100%;
			margin: 5px 0px 20px 0px;
			display: none;
			box-shadow:0px 0px 2px rgba(0, 0, 0, 0.1);
		}
	}
}