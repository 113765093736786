// Font weights
$regular: 400;
$medium: 600;
$bold: 800;

// Base Font
$base-font-family: 'Open Sans', sans-serif;
$base-font-weight: $regular;
$base-font-size: 16px;
$base-line-height: 1.6;

//base colors
$color-primary: #67b907;
$color-text: #2b2f37;
$color-light: #eff5f7;
