/*--------------------------------------------------------------
# Style
--------------------------------------------------------------*/
.modal {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	z-index: 1000;
	position: fixed;
	left: 0px;
	top: 0px;
	overflow-y: scroll; /* has to be scroll, not auto */
	-webkit-overflow-scrolling: touch;
	display: none;

	.modal-inner {
		position: relative;
		background: #fff;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.9);
		width: 400px;
		left: 50%;
		margin:100px 0px 100px -200px;
		padding: 30px;

		.close {
			position: absolute;
			right: 15px;
			top: 5px;
			color: rgba(0, 0, 0, 0.8);
			font-size: 26px;
			cursor: pointer;

			&:hover {
				opacity: 0.5;
			}
		}

		.wpcf7-not-valid-tip {
			display: none !important;
		}

		.wpcf7-response-output {
			width: 100%;
			margin: 10px 0px 0px 0px;
			padding: 5px;
			font-size: 14px;
			line-height: 21px;

			&.wpcf7-validation-errors {
				display: none !important;
			}
		}

		h4 {
			font-size: 21px;
			line-height: 28px;
			font-weight: $medium;
			text-align: left;
			margin: 0px;

			span {
				font-weight: bold;
				text-decoration: underline;
			}
		}

		p {
			font-size: 14px;
			line-height: 21px;
		}

		.field {
			width: 100%;
			float: left;
			margin: 0px 0px 10px 0px;

			.label {
				font-weight: $medium;
				font-size: 14px;

				sup {
					color: #f00;
				}
			}

			input {
				border: 1px solid #d3d2d2;
				width: 100%;
				box-sizing: border-box;
				padding: 8px 10px;
				border-radius: 2px;
				box-shadow: inset 1px 2px 2px 0 rgba(0,0,0,.08);
				float: left;

				&.wpcf7-not-valid {
					border: 1px solid #f00;
				}
			}
		}

		input[type="submit"] {
			background: $color-primary;
			color: #fff;
			padding: 8px 20px;
			border: 0px;
			width: 100%;
			font-size: 18px;
			font-weight: $medium;
			border-radius: 4px;

			&:hover {
				color: #fff;
				text-decoration: none;
				background: darken($color-primary, 5);
			}
		}

		.ajax-loader {
			display: none !important;
		}

		.req {
			font-size: 14px;
			display: block;
		}
	}
}

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {}

@include breakpoint(sm) {}
