/*--------------------------------------------------------------
# Style
--------------------------------------------------------------*/
.content-info {
	background: $color-light;
	width: 100%;
	float: left;
	padding: 20px 0px;

	span {
		font-weight: $bold;
	}

	a {
		color: $color-text;
		text-decoration: underline;
	}
}

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}