.quote {
	width: 100%;
	float: left;
	text-align: center;
	padding: 70px 0px 50px 0px;
	background: #0aabf6;

	h4 {
		font-size: 26px;
		color: #fff;
		font-weight: bold;
	}

	span {
		color: #fff;
	}
}